// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import AnimateIn from '../animate-in';
import { SecondaryButton } from '../elements/buttons';
import Form from '../forms/contact-form';

// Styles
import './styles.scss';

const animateBlocks = () => {
  // Blocks on hero component
  const firstBlock = document.querySelector('.hero-box-container .block-one');
  const secondBlock = document.querySelector('.hero-box-container .block-two');
  const thirdBlock = document.querySelector('.hero-box-container .block-three');
  const fourthBlock = document.querySelector('.hero-box-container .block-four');

  if (document.body.scrollTop >= 0 || document.documentElement.scrollTop >= 0) {
    if (firstBlock) {
      firstBlock.style.transform = `translateX(${window.pageYOffset}px)`;
    }
    if (secondBlock) {
      secondBlock.style.transform = `translateX(-${window.pageYOffset}px)`;
    }
    if (thirdBlock) {
      thirdBlock.style.transform = `translateX(-${window.pageYOffset}px)`;
    }
    if (fourthBlock) {
      fourthBlock.style.transform = `translateX(${window.pageYOffset}px)`;
    }
  }
};

const Hero = (props) => {
  const {
    title,
    description,
    location,
    backgroundColor,
    backgroundImage,
    backgroundMobileImage,
    style,
    innerRef,
    contentColor,
    showButton,
    showForm,
    showFormPartnerBoltAccessGoogle,
    showFormPartnerBoltAccess360,
    longHeader,
    showFormPartnerBoltAccessBing,
    showFormPartnerBoltAccessProgrambusiness,
  } = props;

  // Hero styles
  const heroStyles = {
    ...(backgroundColor && {
      backgroundColor,
    }),
    ...(backgroundImage && {
      backgroundImage,
    }),
  };

  let heroClass = !description ? 'hero-content single-title' : 'hero-content';
  heroClass = longHeader ? `${heroClass} longHeader` : heroClass;
  // Handle scroll
  useEffect(() => {
    window.addEventListener('scroll', animateBlocks);

    return () => {
      window.removeEventListener('scroll', animateBlocks);
    };
  }, []);

  // Return
  return (
    <div className="hero-container">
      <section
        className={
          backgroundImage || backgroundMobileImage
            ? 'hero image-content'
            : 'hero'
        }
        style={heroStyles}
      >
        {backgroundImage || backgroundMobileImage ? (
          <figure className="hero-image">
            <GatsbyImage
              className="desktop-image object-fit-image"
              image={getImage(backgroundImage)}
              alt={title}
            />
            {backgroundMobileImage ? (
              <GatsbyImage
                className="mobile-image object-fit-image"
                image={getImage(backgroundMobileImage)}
                alt={title}
              />
            ) : (
              <GatsbyImage
                className="mobile-image object-fit-image"
                image={getImage(backgroundMobileImage)}
                alt={title}
              />
            )}
          </figure>
        ) : null}
        <div
          className={
            backgroundImage || backgroundMobileImage
              ? 'overlay hero-with-image'
              : 'overlay'
          }
        >
          {backgroundImage || backgroundMobileImage ? (
            <div className="hero-box-container">
              <span className="block-one" />
              <span className="block-two" />
              <span className="block-three" />
              <span className="block-four" />
            </div>
          ) : null}
          <div className="wrapper">
            <ul>
              {location
                ? location.map((items) => (
                    <li key={items.id}>
                      <Link
                        style={contentColor ? { color: contentColor } : null}
                        to={items.location}
                      >
                        {items.value}
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
            <div className={heroClass} style={style} ref={innerRef}>
              {title ? (
                <h1
                  style={contentColor ? { color: contentColor } : null}
                  className={description ? 'hero-title' : 'hero-title'}
                >
                  {parse(title)}
                </h1>
              ) : null}
              {description ? (
                <p style={contentColor ? { color: contentColor } : null}>
                  {parse(description)}
                </p>
              ) : null}
            </div>
            {showButton ? (
              <div className="home-button" style={style} ref={innerRef}>
                <SecondaryButton text="Back to Homepage" link="/" />
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {showForm ? (
        <div className="form">
          <h5>Want to learn more?</h5>
          <Form
            portalId="7013510"
            formId="b4121436-49ff-4f57-b493-4c555cf4fe28"
            targetId="carriert-bg-form"
          />
        </div>
      ) : null}
      {showFormPartnerBoltAccessGoogle ? (
        <div className="form">
          <h5>Want to learn more?</h5>
          <Form
            portalId="7013510"
            formId="5d0acb8f-5096-47a6-b9ab-4f31d7cc76e2"
            targetId="carriert-bg-form"
          />
        </div>
      ) : null}
      {showFormPartnerBoltAccess360 ? (
        <div className="form">
          <h5>Want to learn more?</h5>
          <Form
            portalId="7013510"
            formId="55ca86e8-7699-402a-be54-a843b58f68ae"
            targetId="carriert-bg-form"
          />
        </div>
      ) : null}
      {showFormPartnerBoltAccessBing ? (
        <div className="form">
          <h5>Want to learn more?</h5>
          <Form
            portalId="7013510"
            formId="062f9c93-2d25-4abc-a29f-aef62a9d323d"
            targetId="carriert-bg-form"
          />
        </div>
      ) : null}
      {showFormPartnerBoltAccessProgrambusiness ? (
        <div className="form">
          <h5>Want to learn more?</h5>
          <Form
            portalId="7013510"
            formId="5e82b6c0-9816-4e7d-ae20-5d891b4d46de"
            targetId="carriert-bg-form"
          />
        </div>
      ) : null}
      {showFormPartnerBoltAccess360 ? (
        <div className="form">
          <h5>Want to learn more?</h5>
          <Form
            portalId="7013510"
            formId="55ca86e8-7699-402a-be54-a843b58f68ae"
            targetId="carriert-bg-form"
          />
        </div>
      ) : null}
    </div>
  );
};

// Proptypes
Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  location: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundMobileImage: PropTypes.string,
  contentColor: PropTypes.string,
  showButton: PropTypes.bool,
  showForm: PropTypes.bool,
  showFormPartnerBoltAccessGoogle: PropTypes.bool,
  showFormPartnerBoltAccess360: PropTypes.bool,
  showFormPartnerBoltAccessBing: PropTypes.bool,
  showFormPartnerBoltAccessProgrambusiness: PropTypes.bool,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
  longHeader: PropTypes.bool,
};

// Default props
Hero.defaultProps = {
  location: '',
  backgroundColor: '',
  backgroundImage: '',
  backgroundMobileImage: '',
  contentColor: '',
  showButton: false,
  showForm: false,
  showFormPartnerBoltAccessGoogle: false,
  showFormPartnerBoltAccess360: false,
  showFormPartnerBoltAccessBing: false,
  showFormPartnerBoltAccessProgrambusiness: false,
  longHeader: false,
};

export default AnimateIn(Hero);
